import React from "react"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { Tooltip, Group, ActionIcon, UnstyledButton, useMantineTheme, MantineTheme, Loader } from "@mantine/core"
import { connect } from "react-redux"
import classes from "@/pages/search/grassroots/GrassrootsInlineRightSection.module.css"
import { Icon } from "@/components/Icon"
import { ActionModal } from "@/pages/search/grassroots/ActionModal/ActionModal"
import { api } from "@/api"
import { VideoModal } from "@/pages/search/grassroots/VideoModal/VideoModal"

interface GrassrootsInlineRightSectionProps {
    actionId: number
    uniqueFlvId: string
    confirmed: boolean
    videoUrl: string
    hideGrassrootsAction: (uniqueFlvId: string, inlineId: number) => void
}

const notify = (title: string, message: string, success: boolean, theme: MantineTheme) => {
    notifications.show({
        title,
        message,
        autoClose: false,
        icon: <Icon icon={success ? "check" : "times"} color={success ? theme.colors.green[5] : theme.colors.red[5]} />,
        closeButtonProps: { "aria-label": "Dismiss notification" },
        color: success ? "green.0" : "red.0",
    })
}

const deleteVideo = async (actionId: number) => {
    return await api.patch(
        { model: GrassrootsSupporterAction, primaryKey: actionId, action: "archive_video_action" },
        { archived: true },
    )
}

const downloadVideo = async (actionId: number) => {
    const response = await api.get({
        model: GrassrootsSupporterAction,
        primaryKey: actionId,
        action: "download_story_video",
    })
    const data = await response.json()
    const videoResponse = await fetch(data.download_url)
    const video = await videoResponse.blob()
    const url = window.URL.createObjectURL(video)
    const anchor = document.createElement("a")
    anchor.href = url
    anchor.setAttribute("download", `story-${actionId}.mp4`)
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(url)
}

const { GrassrootsSupporterAction } = DjangIO.app.grassroots.models

const UnconnectedGrassrootsInlineRightSection = (props: GrassrootsInlineRightSectionProps) => {
    const [isApproveModalOpen, approveModalActions] = useDisclosure()
    const [isDeleteModalOpen, deleteModalActions] = useDisclosure()
    const theme = useMantineTheme()
    const [isVideoModalOpen, videoModalActions] = useDisclosure()

    const [isApproving, setIsApproving] = React.useState(false)
    const [isRejecting, setIsRejecting] = React.useState(false)
    const [isDownloading, setIsDownloading] = React.useState(false)

    const handleApprove = () => {
        setIsApproving(true)
        api.patch({ model: GrassrootsSupporterAction, primaryKey: props.actionId.toString() }, { confirmed: true })
            .then(() => {
                approveModalActions.close()
                videoModalActions.close()
                notify("Success", "The action has been approved", true, theme)
                props.hideGrassrootsAction(props.uniqueFlvId, props.actionId)
            })
            .catch(() => notify("Failed", "Unable to approve the action", false, theme))
            .finally(() => setIsApproving(false))
    }

    const handleDelete = () => {
        setIsRejecting(true)
        deleteVideo(props.actionId)
            .then(() => {
                deleteModalActions.close()
                videoModalActions.close()
                notify("Success", "The action has been deleted", true, theme)
                props.hideGrassrootsAction(props.uniqueFlvId, props.actionId)
            })
            .catch(() => notify("Failed", "Unable to delete the action", false, theme))
            .finally(() => setIsRejecting(false))
    }

    const handleDownload = () => {
        setIsDownloading(true)
        downloadVideo(props.actionId)
            .catch(() => notify("Failed", "Unable to download the video", false, theme))
            .finally(() => setIsDownloading(false))
    }

    return (
        <>
            <div className={classes.wrapper}>
                <UnstyledButton className={classes.overlayWrapper} onClick={videoModalActions.open}>
                    <iframe src={props.videoUrl} title="video thumbnail" className={classes.videoThumbnail}></iframe>
                    <div className={classes.overlay}></div>
                </UnstyledButton>
                <div className={classes.iconsWrapper}>
                    <Group spacing="xs">
                        {!props.confirmed && (
                            <Tooltip withArrow label="Approve" position="bottom">
                                <ActionIcon
                                    aria-label="Approve"
                                    onClick={approveModalActions.open}
                                    className={classes.icon}
                                >
                                    <Icon icon="check"></Icon>
                                </ActionIcon>
                            </Tooltip>
                        )}
                        <Tooltip withArrow label="Reject" position="bottom">
                            <ActionIcon
                                aria-label="Reject"
                                onClick={deleteModalActions.open}
                                className={classes.deleteIcon}
                            >
                                <Icon icon="trash"></Icon>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                    <Tooltip withArrow label="Download" position="bottom">
                        {isDownloading ? (
                            <Loader size="xs" variant="dots" />
                        ) : (
                            <ActionIcon aria-label="Download" onClick={handleDownload} className={classes.icon}>
                                <Icon icon="arrow-to-bottom"></Icon>
                            </ActionIcon>
                        )}
                    </Tooltip>
                </div>
                <div className={classes.links}>
                    <UnstyledButton className={classes.editButton} onClick={() => console.log("asked to edit")}>
                        Ask to edit
                    </UnstyledButton>
                    <span className={classes.dot}></span>
                    <a href="https://www.quorum.us" target="_blank" rel="noreferrer" className={classes.learnMore}>
                        Learn more
                    </a>
                </div>
            </div>

            <VideoModal
                isOpen={isVideoModalOpen}
                onClose={videoModalActions.close}
                videoUrl={props.videoUrl}
                openApproveModal={approveModalActions.open}
                openRejectModal={deleteModalActions.open}
                hideApprove={props.confirmed}
                handleDownload={handleDownload}
                isDownloading={isDownloading}
            />

            <ActionModal
                title="Approve story"
                description="Story will be sent to legislators right away"
                isOpen={isApproveModalOpen}
                onClose={approveModalActions.close}
                iconConf={{
                    icon: "check",
                    color: "green",
                }}
                cancelButtonConf={{
                    text: "Cancel",
                    onClick: approveModalActions.close,
                }}
                confirmButtonConf={{
                    text: "Approve",
                    onClick: handleApprove,
                    isLoading: isApproving,
                }}
            />

            <ActionModal
                title="Are you sure?"
                description="This action cannot be undone."
                isOpen={isDeleteModalOpen}
                onClose={deleteModalActions.close}
                iconConf={{
                    icon: "trash",
                    color: "red",
                }}
                cancelButtonConf={{
                    text: "Reject",
                    extraClass: classes.rejectButtonExtraClass,
                    variant: "transparent",
                    onClick: handleDelete,
                    isLoading: isRejecting,
                }}
                confirmButtonConf={{
                    text: "Keep",
                    onClick: deleteModalActions.close,
                }}
            />
        </>
    )
}

export const hideGrassrootsAction = (uniqueFlvId: string, inlineId: number) => async (dispatch) => {
    return await dispatch({
        // this needs to be hard coded because I cannot import
        // from action types without creating a dependency between DjangIO and QuorumGrassroots
        type: "UPDATE_INLINE_APPEARANCE",
        uniqueFlvId,
        inlineId,
        newAppearanceValue: { isHidden: true },
    })
}

// we need to keep it for now since we cannot hide actions without redux
// but we should not be adding other actions here since we are trying to move away from redux
export const GrassrootsInlineRightSection = connect(null, { hideGrassrootsAction })(
    UnconnectedGrassrootsInlineRightSection,
)
