import type { MantineThemeOverride } from "@mantine/core"
import { rem } from "@mantine/core"

// Main color used throughout the site
export const QuorumBlue = "#503ABD"
export const LightBlue = "#e7eaf3" // <--- TODO this is not actually LightBlue

// New Brand Colors
export const Brand = {
    INDIGO: "#221F54",
    ROYAL_PURPLE: "#4E30A1",
    ULTRAVIOLET: "#7259EF",
    PLUM: "#535383",
    BRASS: "#A88F4D",
    COOL_GRAY: "#D5DCEF",
}

export const PrimaryPurple = "#503ABD"

export const CloudBurst = "#2F1980"

// static file stub
export const StaticFiles = "https://quorum-static.s3.amazonaws.com"

// Main greys used throughout the site. First two used most often
export const QuorumGrey1 = "#ecf0f1"
export const QuorumGrey2 = "#b4bcc2"
export const QuorumGrey3 = "#95a5a6"
export const QuorumGrey4 = "#7b8a8b"
export const QuorumGrey5 = "#ccc"
export const QuorumGrey6 = "#f5f5f5"
export const QuorumGrey7 = "#777"
export const QuorumGrey8 = "#F8F9F9"
export const QuorumGrey9 = "#9EA9AA"
export const QuorumGrey10 = "#979797"
export const DarkGrey = "#687374"
export const PrivateGrey = "#e6e6e6"
export const StandardGrey = "#666"
export const SelectBorderGrey = "#b6c0da"
export const FormControlBorderColor = "#DCE4EC"
export const Iron = "#D3D7D8"

export const LightTransparentGrey = "rgba(0, 0, 0, 0.04)"
export const DTVLightTransparentGrey = "rgba(48, 62, 96, 0.05)"

export const LetterPaperSizeWidth = "21.59cm"
export const LetterPaperSizeHeight = "23.9cm"

export const HeaderColor = "#e7eaf3" // lighten(QuorumBlue, 90%)
export const StripeColor = HeaderColor
export const HeaderBorderColor = "#FCFFFF" // lighten(QuorumBlue, 80%)
export const HeaderBorderBlueColor = "#526aa4"

export const IndependentColor = QuorumGrey2

// Logo Colors
export const DarkPurple = "#3D3262" // Quorum
export const MiddlePurple = "#5f2b7b"
export const LightPurple = "#503ABD" // Data Driven Politics
export const VeryLightPurple = "#e3d7e9" // lighten(LightPurple, 80%)

export const ActiveButtonPurpleColor = "#372043"
export const ActiveButtonPurpleBorderColor = "#633a7b"

// Text Colors
export const BodyTextColor = "#0e121c"
export const HyperlinkColor = "#854EA4"
export const White = "#fff"
export const Black = "#000"

// Republican Colors
export const RepublicanMainColor = "#D62728"
export const RepublicanDarkColor = "#951b1c" // Borders, etc.
export const RepublicanLightColor = "#ff3739" // Hovers, etc

// Democrat Colors
export const DemocratMainColor = "#1f77b4"
export const DemocratDarkColor = "#15537d"
export const DemocratLightColor = "#2caaff"

// Puerto Rico Colors. Stole these bois by eye dropping the party logos lol.
// PNP
export const PNPMainColor = "#203d8a"
// PPD
export const PPDMainColor = "#e81728"
// PIP
export const PIPMainColor = "#227d16"
// PPT
export const PPTMainColor = "#62378d"

// EU Colors!!
// EPP
export const EPPMainColor = "#3399FF"

// S&D
export const SDMainColor = "#FF0000"

// ECR
export const ECRMainColor = "#0000FF"

// ALDE
export const ALDEMainColor = "#FFD700"

// GUE/NGL
export const GUENGLMainColor = "#990000"

// Greens-EFA
export const GreensEFAMainColor = "#009900"

// EFDD
export const EFDDMainColor = "#24B9B9"

// ENL
export const ENLMainColor = "#2B3856"

// NI
export const NIMainColor = QuorumGrey5

// PFE
export const PFEMainColor = "#1F578A"

// ESN
export const ESNMainColor = "#2F1C59"

// Other
export const OtherColor1 = "#4D2D5F"
export const OtherColor2 = "#2F3A54"
export const OtherColor3 = "#2B1F51"

// Hover Color (Used in spreadsheet, manage lists)
export const HoverColor = "#D3CFDD"
export const DarkHoverColor = "#A09CAA" // darken(HoverColor, 20)

// Social Colors
export const FacebookBlue = "#3B5998"
export const FacebookIconBlue = "#4267B2"
export const TwitterLightBlue = "#00aced"
export const TwitterBlue = "#4099FF"
export const TwitterDarkBlue = "#0084B4"
export const TwitterDarkestBlue = "#0079A5"
export const LinkedInBlue = "#0177b5"
export const GooglePlusOrange = "#dd5044"
export const PinterestRed = "#d8121d"

export const MediumGreen = "#02b875"
export const YoutubeRed = "#cc181e"
export const InstagramPurpleViolet = "#8a3ab9"
export const InstagramBlue = "#4c68d7"
export const InstagramMaroon = "#cd486b"
export const InstagramOrange = "#fbad50"
export const InstagramYellow = "#fccc63"
export const InstagramRedViolet = "#bc2a8d"
export const InstagramRedOrange = "#e95950"

export const ActionButtonHover = "#adadad"
export const DropdownItemHover = "#ebebeb"
export const SearchGraphBorder = "#DDD"
export const SupporterColor = "#555"
export const PCardColor = "#0088cc"

export const CompletedGreen = "#5F8C2E"

// VARIABLES
export const RegularFontWeight = 400
export const SlightlyBoldFontWeight = 500
export const BorderRadius = "4px"
export const LightBorderColor = "#ced5e7"

export const StatGrey = "#6e7890"

export const CokeRed = "#F40009"
export const CokeDarkRed = "#900E0E"
export const LineHeight = 1.428571429

// Texture(u, n="5fb28db9f41c")
//     return url(StaticFiles + '/static/frontend/images/textures/' + u + ".png?" + n)

// BrightSquares = Texture('bright-squares')
// AlwaysGrey = Texture('always-grey')
// BlackThread = Texture('black-thread')
// Cartographer = Texture('cartographer')
// CheckeredLight = Texture('checkered-light')
// Diamonds = Texture('diamonds')
// Dimension = Texture('dimension')
// DoubleLined = Texture('double-lined')
// FakeLuxury = Texture('fake-luxury')
// LightHoneyComb = Texture('light-honeycomb')
// Psychedelic = Texture('psychedelic')

// QuorumBlueRGBA(avalue=1)
//     rgba(48, 62, 96, avalue)

export const PageHeaderPadding = "10px"
export const PageHeaderFontSize = "28px"

// PrivateCommentColor = '#5ab4c2'
// Mixins of textures
// PurpleBoxes()
//     background-image BrightSquares
//     background-color DarkPurple
// BlueWeb()
//     background-image AlwaysGrey
//     background-color QuorumBlue

// http://kinzler.com/picons/ftp/img/colorset.gif
// http://cs.gettysburg.edu/~duncjo01/archive/icons/aicons/
export const VotePassedColor = "#2E8B57"
export const VoteFailedColor = "#FF510C"
export const VotePresentColor = "#FFD700"
export const VoteMissedColor = "#A020F0"
export const VoteUnknownColor = "#BEBEBE"

// When you hover over clickable elements they should
// darken 10%
// clickable(c)
//     &:hover
//         color darken(c, 10%)
export const FrameSize = "60px"
export const ScrollBarSize = "14px"
export const ThickScrollBarSize = "18px"

export const LargeFontSize = "20px"

export const SidebarBorderColor = "#3c4147"
export const TopBarBorderColor = "#c6d0da"
export const TopBarTextColor = "white"
export const TopBarHoverColor = "#5f6f81"

// SidebarGradient()
//     background: linear-gradient(rgba(white, 0.96), rgba(white, 0.96)), QuorumBlue

// colors for notifications
export const UnseenColor = "#D5D7DC"
export const SeenColor = "white"
export const SeenFadeDuration = ".1s"

export const FormLabelSize = "16px"
export const FormFieldHeight = "44px"

export const DropdownStripeColor = `rgba(${QuorumGrey1}, .5)`
export const StatListLength = "405px"
export const QuorumBorder = `1px solid ${QuorumBlue}`
export const BannerSize = "100px"
export const InlineHoverColor = "#fbfcfc"
// eslint-disable-next-line no-useless-escape
export const SearchPlusIcon = "F00E"

export const InteractionLoggerSpacing = "15px"

export const SelectDropdownColor = "#b2c1c2"

export const SearchifyColor = "rgba(48, 62, 96, 0.28)"
export const SearchifyHoverColor = "rgba(48, 62, 96, 0.04)"
export const SearchifyDocumentHoverColor = "rgba(48, 62, 96, 0.34)"

export const ProfileDetailGrey = "#808080"

// .disable-with-tooltip
//     opacity 0.65

export const BorderColor = "#526aa4"

// Marketing Site
export const DefaultFontWeight = 400
export const MobileWidth = "992px"
export const SmallMobileWidth = "768px"
export const LoginMobileWidth = "800px"

export const SectionHeaderFontWeight = 400
export const SectionHeaderFontSize = "24px"

export const UnsubscribeMobileWidth = "800px"
export const NavBarBreakPoint = "884px"

export const RequestDemoPurple = "#8a3bb7"
export const RequestDemoBorderPurple = "#563767"
export const RequestDemoHoverPurple = "#854ea4"
export const EmailSignupBorder = "#dda0ff"

export const FeedItemBorderColor = "rgb(221,226,238)"

export const RequestDemoBackgroundColor = "rgba(61, 50, 98, 0.92)"
export const RequestDemoBackgroundColorNoTransparency = "rgba(61, 50, 98, 1)"
export const RequestDemoHoverBackgroundColor = "rgba(43, 31, 81, 0.99) !important"

// Redux form variables
export const ReduxFormLineHeight = "44px"
export const ReduxFormErrorColor = "#E76B74"
export const ReduxFormWarningColor = "#FACE00"
export const ReduxFormFontSize = "17px"
export const ReduxFormInputSize = "16px"
export const ReduxFormMarginSize = "10px"

// Other Form Variables
export const ErrorRed = "#B71812"
export const WarningMarigold = "#ED7806"
export const unnamed222222 = "#222222"
export const DarkerPorcelain = "#F1F3F4"

// ReduxFormErrorFocus()
//     border-color ReduxFormErrorColor
//     outline 0
//     -webkit-box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormErrorColor, .6)
//     box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormErrorColor, .6)

// ReduxFormWarningFocus()
//     border-color ReduxFormWarningColor
//     outline 0
//     -webkit-box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormWarningColor, .6)
//     box-shadow inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px alpha(ReduxFormWarningColor, .6)

// Help Center
export const HelpCenterBlueBackground = "rgba(48, 62, 96, 0.7)"
export const HelpCenterHeroImageHeight = "235px"
export const HelpCenterQSExitMargin = "20px"
export const HelpCenterPanelHover = "#dee2e3"
export const HelpCenterChatButton = QuorumGrey8
export const HelpCenterChatHover = "#EAEBEF"
export const HelpCenterChatBorder = "#E0E4E9"
export const HelpCenterSilverChalice = "#AAA"
export const HelpCenterAquaHaze = "#EDF1F5"
export const HelpCenterBannerStart = "#BDB8FA"

// HelpCenterLoadingText()
//     text-align center
//     font-size 42px
//     font-weight 300
//     color QuorumBlue
//     margin-top 20px

export const SaturatedColors = ["#FF00FF", "#0000FF", "#00FF00", "#FF0000", "#FFFF00", "#000000", "#FFFFFF"]

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

// Set to match our Mantine theme's xs breakpoint of 36em / 576px
export const mobileBreakpoint = 576

export const topNavigationFixedHeight = 60

// Min-widths to correspond to the popdown's responsive size while it's open
export const DESKTOP_POPDOWN_CONTAINER_WIDTH = "82rem"
export const TABLET_POPDOWN_CONTAINER_WIDTH = "51rem"

/*
Custom theme for Mantine.
See https://mantine.dev/theming/theme-object/.
Default values: https://github.com/mantinedev/mantine/blob/v6/src/mantine-styles/src/theme/default-theme.ts
*/
export const QuorumTheme: MantineThemeOverride = {
    /*
    Our new design system uses Mantine. You can read more about how Mantine handles color here: https://v6.mantine.dev/theming/colors/
    Our main design document can be found at https://plum.quorum.us/4a128e208/p/066518-colors
    There's additional information on this Figma file, but it is in the process of being deprecated: https://www.figma.com/file/mjUSsYy7JxOtylceOQgr3r/Design-System?node-id=2178%3A4119&t=9WfvKKssZYnaR9jw-1
    */
    colors: {
        primary: [
            "#FBFAFE",
            "#EBEAFD",
            "#D9D8FC",
            "#BDB8FA",
            "#9B8FF6",
            "#7259EF",
            "#6149D6",
            "#503ABD",
            "#3E2AA3",
            "#2F1980",
        ],
        brand: ["#7259EF", "#4E30A1", "#221F54", "#BEA552", "#A88F4D", "#826D45"],
        secondary: [
            "#F0E5F1",
            "#DBBFDF",
            "#C496CA",
            "#AB6EB4",
            "#8E57AB",
            "#8A3B93",
            "#7E378E",
            "#6E3085",
            "#5F2B7B",
            "#32004E",
        ],
        green: [
            "#F0FDF2",
            "#DDFBE1",
            "#BDF5C6",
            "#BDF5C6",
            "#4FD967",
            "#2ACB47",
            "#1B9E32",
            "#197C2B",
            "#196227",
            "#062D0F",
        ],
        orange: [
            "#FEF2E0",
            "#FDDFB2",
            "#FBCA80",
            "#F9B34F",
            "#F8A429",
            "#F69509",
            "#F38907",
            "#ED7906",
            "#E76A05",
            "#DD5004",
        ],
        red: [
            "#FEE8EB",
            "#FCC9CB",
            "#E7928E",
            "#DB6963",
            "#E1483C",
            "#E2361D",
            "#D52D1E",
            "#C32219",
            "#B71812",
            "#A80903",
        ],
        gray: [
            "#FAFBFC",
            "#F1F3F5",
            "#E9ECEF",
            "#DEE2E6",
            "#CED4DA",
            "#ADB5BD",
            "#868E96",
            "#495057",
            "#343A40",
            "#212529",
        ],
        violet: [
            "#FBFAFE",
            "#EBEAFD",
            "#D9D8FC",
            "#BDB8FA",
            "#9B8FF6",
            "#7259EF",
            "#6149D6",
            "#503ABD",
            "#3E2AA3",
            "#2F1980",
        ],
    },
    components: {
        Alert: {
            styles: (theme) => ({
                icon: {
                    marginRight: theme.spacing.sm,
                },
                label: {
                    fontWeight: 500,
                    color: theme.colors.gray[8],
                },
            }),
        },
        Anchor: {
            styles: (theme) => ({
                root: {
                    color: theme.colors.violet[6],
                    fontWeight: 400,
                    // We have to use !important here because the stylus file in
                    // app/static/frontend/profiles-new/components/DataTableView/index.styl defines higher specificity
                    // rules and the underline will never show for our links. We can't simply remove those stylus rules
                    // because it adversely affects other parts of the application, like links in drop down menus.
                    textDecoration: "underline !important",
                    textUnderlinePosition: "under",
                    transition: "background-color .25s linear",

                    "&:hover, &:focus": {
                        backgroundColor: theme.colors.yellow[1],
                    },

                    "& [data-icon='external-link']": {
                        marginLeft: 4,
                        textDecoration: "none",
                    },

                    svg: {
                        marginLeft: 2,
                    },
                },
            }),
        },
        Badge: {
            defaultProps: {
                radius: "sm",
                size: "lg",
                variant: "light",
            },
            styles: {
                root: {
                    fontWeight: 400,
                },
            },
            sizes: {
                md: () => ({
                    root: {
                        padding: "0.0625rem 0.375rem",
                    },
                }),
            },
        },
        Button: {
            sizes: {
                xs: (theme) => ({
                    root: {
                        fontSize: theme.fontSizes.sm,
                    },
                    inner: {
                        gap: ".375rem",
                    },
                    leftIcon: {
                        marginRight: "unset",
                        width: ".75rem",
                        height: ".75rem",
                        fontSize: theme.fontSizes.sm,
                    },
                    label: {
                        lineHeight: "1rem",
                    },
                }),
                sm: (theme) => ({
                    root: {
                        height: "2.5rem",
                        fontSize: theme.fontSizes.md,
                        padding: ".75rem 1rem",
                    },
                    inner: {
                        gap: ".375rem",
                    },
                    leftIcon: {
                        marginRight: "unset",
                        width: ".875rem",
                        height: ".875rem",
                        fontSize: theme.fontSizes.sm,
                    },
                }),
            },
            variants: {
                default: (theme) => ({
                    root: {
                        color: theme.colors.primary[8],
                    },
                }),
                outline: (theme) => ({
                    root: {
                        border: `1px solid ${theme.colors.gray[3]}`,
                        backgroundColor: "white",
                        color: theme.colors.gray[7],
                        "&:hover": {
                            border: `1px solid ${theme.colors.violet[2]}`,
                            backgroundColor: theme.colors.violet[2],
                            color: theme.colors.violet[8],
                        },
                        "&:active": {
                            border: `1px solid ${theme.colors.gray[3]}`,
                            backgroundColor: "white",
                            boxShadow: `0 0 0 2px ${theme.colors.violet[5]}`,
                        },
                        "&:focus": {
                            border: `1px solid ${theme.colors.violet[2]}`,
                            backgroundColor: theme.colors.violet[2],
                            color: theme.colors.violet[8],
                            boxShadow: `0 0 0 2px ${theme.colors.violet[5]}`,
                            outline: "none",
                        },
                    },
                }),
                filled: (theme) => ({
                    root: {
                        border: 0,
                        backgroundColor: theme.colors.violet[7],
                        color: "white",
                        boxShadow: "0 0 0 2px transparent",
                        "&:hover": {
                            backgroundColor: theme.colors.violet[9],
                            boxShadow: "0 0 0 2px transparent",
                        },
                        "&:active": {
                            backgroundColor: theme.colors.violet[7],
                            boxShadow: `0 0 0 2px ${theme.colors.violet[5]}`,
                        },
                        "&:focus": {
                            backgroundColor: theme.colors.violet[9],
                            boxShadow: `0 0 0 2px ${theme.colors.violet[5]}`,
                            outline: "none",
                        },
                    },
                }),
                tonal: (theme) => ({
                    root: {
                        border: 0,
                        backgroundColor: theme.colors.violet[2],
                        color: theme.colors.violet[8],
                        "&:hover": {
                            backgroundColor: theme.colors.violet[3],
                            color: theme.colors.violet[9],
                        },
                        "&:active": {
                            border: `1px solid ${theme.colors.gray[3]}`,
                            backgroundColor: theme.colors.violet[1],
                            boxShadow: `0 0 0 ${rem(2)} ${theme.colors.violet[3]}`,
                            color: theme.colors.gray[7],
                        },
                        "&:focus": {
                            backgroundColor: theme.colors.violet[3],
                            boxShadow: `0 0 0 ${rem(2)} ${theme.colors.violet[3]}`,
                            color: theme.colors.violet[9],
                        },
                    },
                }),
                ghost: (theme) => ({
                    root: {
                        border: 0,
                        backgroundColor: "white",
                        color: theme.colors.gray[7],
                        "&:hover": {
                            backgroundColor: theme.colors.violet[0],
                            color: theme.colors.violet[7],
                        },
                        "&:active": {
                            backgroundColor: theme.colors.violet[0],
                            boxShadow: `0 0 0 ${rem(2)} ${theme.colors.violet[5]}`,
                            color: theme.colors.gray[7],
                        },
                        "&:focus": {
                            backgroundColor: theme.colors.violet[0],
                            boxShadow: `0 0 0 ${rem(2)} ${theme.colors.violet[5]}`,
                            color: theme.colors.violet[7],
                        },
                    },
                }),
            },
            styles: {
                inner: {
                    fontWeight: 400,
                },
                root: {
                    transition: "background 0.25s ease-in-out",

                    "&:active": {
                        border: "none",
                        transform: "none",
                    },

                    "&:focus": {
                        outlineOffset: 0,
                    },
                },
            },
        },
        CheckboxGroup: {
            styles: (theme) => ({
                label: {
                    marginBottom: theme.spacing.xs,
                },
            }),
        },
        Checkbox: {
            sizes: {
                sm: (theme) => ({
                    icon: {
                        "&&": {
                            width: ".75rem",
                        },
                    },
                    inner: {
                        height: 16,
                        width: 16,
                    },
                    labelWrapper: {
                        fontSize: 16,
                    },
                }),
            },
            styles: (theme) => ({
                root: {
                    "input[type=checkbox]": {
                        margin: "0",
                    },
                },
                body: {
                    display: "flex",
                    alignItems: "center",
                },

                input: {
                    height: "inherit",
                    width: "inherit",
                    borderColor: theme.colors.primary[3],
                    borderRadius: ".125rem",

                    "&:checked": {
                        backgroundColor: theme.colors.primary[5],
                        borderColor: theme.colors.primary[5],
                    },
                },

                label: {
                    margin: 0,
                    paddingLeft: 8,
                    fontWeight: 400,
                    color: theme.colors.gray[7],
                },
            }),
        },
        Chip: {
            styles: (theme) => ({
                label: {
                    margin: 0,
                    color: theme.colors.primary[8],
                    border: `1px solid ${theme.colors.gray[3]}`,
                    borderRadius: 24,
                    fontWeight: 400,
                    fontSize: 14,
                    paddingLeft: 10,
                    paddingRight: 10,

                    "&[data-checked]": {
                        borderColor: theme.colors.primary[8],
                        backgroundColor: theme.colors.gray[1],
                    },
                },
            }),
        },
        DatePicker: {
            defaultProps: {
                ariaLabels: {
                    nextDecade: "Next decade",
                    previousDecade: "Previous decade",
                    nextYear: "Next year",
                    previousYear: "Previous year",
                    nextMonth: "Next month",
                    previousMonth: "Previous month",
                    yearLevelControl: "Change to decade view",
                    monthLevelControl: "Change to year view",
                },
            },
        },
        DatePickerInput: {
            defaultProps: {
                firstDayOfWeek: 0,
                getMonthControlProps: (date) => ({
                    "aria-label": `Select ${months[date.getMonth()]}`,
                }),
                getDayProps: (date) => ({
                    "aria-label": `Select ${weekdays[date.getDay()]}, ${date.getDate()} ${
                        months[date.getMonth()]
                    } ${date.getFullYear()}`,
                }),
            },
            styles: (theme) => ({
                day: {
                    "&[data-weekend]": {
                        color: theme.colors.gray[7],
                    },
                    "&[data-weekend]&[data-selected]": {
                        color: theme.white,
                    },
                },
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                },
            }),
        },
        Drawer: {
            styles: {
                body: {
                    "&:hover::-webkit-scrollbar-thumb": {
                        background: "rgba(0,0,0,.2)",
                        backgroundClip: "padding-box",
                        border: "2px solid rgba(0,0,0,.0)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "rgba(0,0,0,.0)",
                        borderRadius: "1rem",
                        border: "2px solid rgba(0,0,0,.0)",
                        backgroundClip: "padding-box",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "transparent",
                    },
                    "&::-webkit-scrollbar": {
                        width: "0.5rem",
                        background: "transparent",
                        border: "none",
                    },
                },
            },
        },
        FileInput: {
            styles: {
                root: {
                    // TODO: confirm width with Design team.
                    // Currently used in Issue Management form.
                    width: "100%",
                },
            },
        },
        Grid: {
            defaultProps: {
                columns: 8,
            },
        },
        Input: {
            styles: (theme) => ({
                input: {
                    borderColor: theme.colors.gray[5],
                    marginBottom: 0,

                    "&:hover": {
                        border: `1px solid ${theme.colors.violet[5]}`,

                        "&[data-invalid]": {
                            border: `1px solid ${theme.colors.red[9]}`,
                        },
                    },
                    "&:focus": {
                        border: `1px solid ${theme.colors.violet[5]}`,
                        boxShadow: `0px 0px 0px 2px rgba(114, 89, 239, 0.48)`,
                    },
                    "&:active": {
                        border: `1px solid ${theme.colors.violet[5]}`,
                        boxShadow: `0px 0px 0px 2px rgba(114, 89, 239, 0.24)`,
                    },
                    "&:disabled": {
                        color: theme.colors.gray[7],
                        border: `1px solid ${theme.colors.gray[2]}`,
                        backgroundColor: theme.colors.gray[0],
                    },
                },
            }),
            sizes: {
                sm: () => ({
                    input: {
                        minHeight: "2.5rem",
                    },
                }),
            },
        },
        InputWrapper: {
            defaultProps: {
                inputWrapperOrder: ["label", "input", "description", "error"],
                labelProps: {
                    size: "md",
                },
            },
            styles: (theme) => ({
                root: {
                    lineHeight: "1.0625rem",

                    "&.read-only .mantine-Input-icon": {
                        color: theme.colors.gray[4],
                    },

                    "&.read-only .mantine-Input-input": {
                        backgroundColor: theme.colors.gray[1],
                        color: theme.colors.gray[7],
                        opacity: 0.6,
                        cursor: "not-allowed",
                        pointerEvents: "none",
                    },
                },
                description: {
                    color: theme.colors.gray[7],
                    fontSize: theme.fontSizes.sm,
                    marginTop: "0.375rem",
                },
                error: {
                    fontSize: theme.fontSizes.sm,
                    marginTop: "0.375rem",
                },
                label: {
                    color: theme.colors.gray[7],
                    fontWeight: 500,
                    fontSize: theme.fontSizes.sm,
                    marginBottom: "0.375rem",

                    "&, span": {
                        lineHeight: "1.0625rem",
                    },

                    "> svg": {
                        marginLeft: 4,
                    },

                    ":has(.quorum-reactselect-wrapper)": {
                        marginBottom: 0,
                    },

                    ".quorum-reactselect-wrapper": {
                        marginTop: "0.375rem",
                    },
                },
            }),
        },
        Menu: {
            styles: (theme) => ({
                item: {
                    "&[data-hovered]": {
                        backgroundColor: "white",
                    },

                    "&[data-hover='true']": {
                        backgroundColor: theme.colors.gray[1],
                    },

                    "&:hover": {
                        backgroundColor: theme.colors.gray[1],
                    },
                },
            }),
        },
        MultiSelect: {
            defaultProps: {
                zIndex: 1001, // above the sticky footer
                disableSelectedItemFiltering: true,
            },
            styles: (theme) => ({
                searchInput: {
                    color: theme.colors.gray[7],
                    fontSize: theme.fontSizes.md,
                },
                input: {
                    // to override the minHeight inherited from Input
                    minHeight: 0,
                    paddingLeft: "0.1875rem",
                },
                values: {
                    height: "100%",
                    marginLeft: 0,
                },
                value: {
                    backgroundColor: theme.colors.gray[1],
                    color: theme.colors.gray[7],
                    fontSize: theme.fontSizes.md,
                    fontWeight: 400,
                    height: "1.75rem",
                    margin: ".25rem .125rem",
                },
                itemsWrapper: {
                    gap: "2px",
                },
                item: {
                    borderRadius: ".25rem",
                    "&:hover, &[data-selected]": {
                        backgroundColor: theme.colors.violet[1],
                        color: theme.colors.violet[8],

                        "&:hover": {
                            backgroundColor: theme.colors.violet[1],
                            color: theme.colors.violet[8],
                        },
                    },
                },
            }),
        },
        Notification: {
            defaultProps: {
                closeButtonProps: {
                    "aria-label": "Dismiss notification",
                    "data-plum-id": "button-dismiss-notification",
                },
            },
            styles: (theme) => ({
                root: {
                    alignItems: "start",
                    border: `1px solid ${theme.colors.gray[2]}`,
                    boxShadow: "0px 4px 8px -4px rgba(0, 0, 0, 0.16)",
                    paddingBottom: "1rem",
                    color: theme.colors.gray[7],
                    marginLeft: "auto",

                    "&[data-with-icon]": {
                        paddingLeft: "0.75rem",
                    },

                    [theme.fn.largerThan("xs")]: {
                        maxWidth: "22.5rem",
                    },
                },
                closeButton: {
                    color: theme.colors.gray[5],
                    width: "2rem",
                    height: "2rem",
                    position: "absolute",
                    right: "0.25rem",
                    top: "0.25rem",
                },
                title: {
                    fontSize: 16,
                    marginBottom: 0,
                },
            }),
        },
        Radio: {
            defaultProps: {
                size: "xs",
            },
            styles: (theme) => ({
                label: {
                    color: theme.colors.gray[7],
                    fontSize: 16,
                    fontWeight: 400,
                    marginBottom: 0,
                    paddingLeft: 8,
                    svg: {
                        marginRight: 6,
                    },
                },
                radio: {
                    width: 16,
                    height: 16,
                    borderRadius: 16,
                    borderColor: theme.colors.primary[3],

                    "&:checked": {
                        backgroundColor: White,
                        borderColor: theme.colors.primary[5],
                    },
                },
                body: {
                    display: "flex",
                    alignItems: "center",
                },
                inner: {
                    alignSelf: "center",
                },
                icon: {
                    color: theme.colors.primary[5],
                },
            }),
        },
        RadioGroup: {
            styles: (theme) => ({
                label: {
                    marginBottom: theme.spacing.xs,
                },
            }),
        },
        RichTextEditor: {
            styles: {
                content: {
                    ".ProseMirror": {
                        minHeight: "200px",
                        overflowWrap: "anywhere",
                    },
                },
            },
        },
        SegmentedControl: {
            defaultProps: {
                radius: "xl",
            },
            styles: (theme) => ({
                root: {
                    height: 40,
                    padding: 4,
                    alignSelf: "start",
                },
                label: {
                    marginBottom: 0,
                    height: 32,
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.colors.gray[7],
                    fontWeight: 500,
                    padding: "8px 15px",

                    "&:hover": {
                        color: theme.colors.primary[8],
                    },

                    "&.mantine-SegmentedControl-labelActive": {
                        color: theme.colors.primary[8],

                        "&:hover": {
                            color: theme.colors.primary[8],
                        },
                    },
                },
            }),
        },
        Select: {
            styles: (theme) => ({
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                },
                item: {
                    color: theme.colors.gray[7],
                    fontSize: theme.fontSizes.md,

                    "&[data-selected], &[data-hovered], &:hover": {
                        backgroundColor: theme.colors.violet[1],
                        color: theme.colors.violet[8],

                        "&:hover": {
                            backgroundColor: theme.colors.violet[1],
                            color: theme.colors.violet[8],
                        },
                    },
                },
                dropdown: {
                    boxShadow: theme.shadows.sm,
                },
            }),
        },
        Switch: {
            defaultProps: {
                size: "md",
            },
            sizes: {
                md: () => ({
                    thumb: {
                        height: "1rem",
                        left: "0.25rem",
                        width: "1rem",
                    },
                    track: {
                        minWidth: "2.5rem",
                    },
                    trackLabel: {
                        minWidth: "calc(2.875rem - 1.5rem)",
                        margin: "0 0 0 calc(1rem + 0.125rem)",

                        "input:checked + * > &": {
                            margin: "0 calc(1rem + 0.125rem) 0 0",
                        },
                    },
                }),
            },
            styles: (theme) => ({
                thumb: {
                    border: "unset",
                },
                track: {
                    backgroundColor: theme.colors.gray[3],
                    border: "unset",
                },
                label: {
                    color: theme.colors.gray[7],
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 500,
                    svg: {
                        marginLeft: 4,
                    },
                },
                input: {
                    "&:checked+.mantine-Switch-track": {
                        borderColor: theme.colors.violet[5],
                        backgroundColor: theme.colors.violet[5],
                    },
                },
            }),
        },
        Tabs: {
            defaultProps: {
                color: "violet.5",
            },
            styles: (theme) => ({
                tabList: {
                    height: "2.5rem",
                },
                tab: {
                    "&[data-active] .mantine-Tabs-tabLabel": {
                        color: theme.colors.gray[9],
                    },
                    padding: ".75rem 1rem",
                },
                tabLabel: {
                    fontSize: "0.875rem",
                    color: theme.colors.gray[7],
                },
            }),
        },
        Text: {
            defaultProps: {
                color: "gray.7",
            },
        },
        Textarea: {
            defaultProps: {
                autosize: true,
                minRows: 4,
                maxRows: 8,
                inputWrapperOrder: ["label", "input", "description", "error"],
            },
            styles: (theme) => ({
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                },
                wrapper: {
                    marginBottom: 0,
                },
            }),
        },
        TextInput: {
            styles: (theme) => ({
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                },
            }),
        },
        TimeInput: {
            styles: (theme) => ({
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                    marginBottom: 0,
                },
            }),
        },
        Title: {
            styles: (theme) => ({
                root: {
                    "&": {
                        color: theme.colors.gray[9],
                    },
                },
            }),
        },
        Tooltip: {
            defaultProps: {
                withArrow: true,
                withinPortal: true,
                multiline: true,
            },
            styles: () => ({
                tooltip: {
                    maxWidth: 300,
                    textAlign: "center",
                },
            }),
        },
        YearPickerInput: {
            defaultProps: {
                ariaLabels: {
                    nextDecade: "Next decade",
                    previousDecade: "Previous decade",
                },
                getYearControlProps: (date: Date) => ({
                    "aria-label": `Select ${date.getFullYear()}`,
                }),
            },
            styles: (theme) => ({
                input: {
                    color: theme.colors.gray[9],
                    fontSize: theme.fontSizes.md,
                },
            }),
        },
    },
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.375rem",
    },
    globalStyles: (theme) => ({
        ":root": {
            // Maybe also store these as theme variables under "other"?
            "--navbar-height": "60px",
            "--header-height": "60px",
            "--topbar-height": "3.75rem",
            "--tab-list-height": "2.5rem",
            "--side-navbar-width": "0px",
            "--side-ai-chat-width": "0px",
            "--form-main-content-max-width": "823px",
            [theme.fn.largerThan("xs")]: {
                "--side-navbar-width": "60px",
            },
            "--profile-modal-header-height": "3.5rem",
            "--profile-actions-height": "3.25rem",

            // color updates for stylus modules and bootstrap components

            "--filled-background": theme.colors.violet[7],
            "--filled-background-hover": theme.colors.violet[9],
            "--filled-background-disabled": theme.colors.violet[1],
            "--filled-color": theme.white,
            "--filled-color-disabled": theme.colors.violet[3],
            "--outlined-background": theme.white,
            "--outlined-background-hover": theme.colors.violet[1],
            "--outlined-color": theme.colors.gray[7],
            "--outlined-color-hover": theme.colors.violet[8],
            "--outlined-border": theme.colors.gray[3],
            "--ghost-background": theme.white,
            "--ghost-background-hover": theme.colors.violet[0],
            "--ghost-color": theme.colors.gray[7],
            "--ghost-color-hover": theme.colors.violet[7],
            "--ghost-red-color": theme.colors.red[9],
            "--ghost-red-background-hover": theme.colors.red[0],
            "--ghost-red-background-disabled": theme.colors.gray[0],
        },
        // This is required to reset the margin on radio inputs because bootstrap
        // is overriding the styling on radio buttons with higher specificity
        // than Mantine. Do not use this as a pattern! If we run into this more,
        // we should discuss another approach to resetting styling.
        'input[type="radio"]': {
            margin: 0,
        },
        // Use <Stack className="nested"> rather than having to create a custom
        // component just for nested Stack components with a single style rule.
        ".mantine-Stack-root.nested": {
            padding: "0 1.5rem",
        },
    }),
    headings: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 400,
        sizes: {
            h1: { fontSize: "1.5rem", lineHeight: 1.35 },
            h2: { fontSize: "1.25rem", lineHeight: 1.4 },
            h3: { fontSize: "1.125rem", lineHeight: 1.45 },
            h4: { fontSize: "1rem", lineHeight: 1.5 },
            h5: { fontSize: "0.875rem", lineHeight: 1.5 },
            h6: { fontSize: "0.875rem", lineHeight: 1.5 },
        },
    },
    shadows: {
        xs: "0px 2px 4px -2px rgba(0, 0, 0, 0.12)",
        sm: "0px 4px 8px -4px rgba(0, 0, 0, 0.16)",
        md: "0px 4px 12px -4px rgba(0, 0, 0, 0.20)",
        lg: "0px 8px 24px -8px rgba(0, 0, 0, 0.24)",
        xl: "0px 12px 40px -12px rgba(0, 0, 0, 0.20)",
    },
    lineHeight: 1.5,
    primaryColor: "primary",
    primaryShade: 8,
    respectReducedMotion: true,
}
