import { useQuery } from "@tanstack/react-query"
import { api } from "@/api"
import { Campaign } from "@/types/djangio"
import { replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions.js"
import { getCSRF } from "QuorumGrassroots/campaign-forms/helpers"

const { ShareStoryType } = DjangIO.app.grassroots.campaign.types

export const useMultiActionChildren = (parentId: string) =>
    useQuery<{ objects: Campaign[] }>({
        queryKey: ["multi-action", parentId],
        queryFn: fetchCampaign,
        enabled: Boolean(parentId),
        staleTime: Infinity,
        select: convertMessages,
    })

function convertMessages(data: { objects: Campaign[] }) {
    const result = data.objects.map((campaign) => {
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_member.value) {
            return convertWriteALetterMessages(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.comment_on_regulation.value) {
            return convertRegulationMessages(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_white_house.value) {
            return convertWriteWhiteHouseMessages(campaign)
        }
        return campaign
    }) as Campaign[]
    return { objects: result }
}

export const convertWriteALetterMessages = (campaign: Campaign) => {
    const messages = {}
    const targets = {}
    const existing_target_ids = {}
    for (const message of campaign.messages as WriteALetterMessages) {
        // Here we want to make sure a legislator receives only one message even if they are in multiple groups
        // We are adding it to the first group they are in - similar to GET_CAMPAIGN_MESSAGES_SUCCESS for Redux
        if (message.target.id in existing_target_ids) {
            continue
        }
        existing_target_ids[message.target.id] = true

        messages[message.group.id] = {
            subject: replaceEditablePlaceholders(message.subject),
            preBody: replaceEditablePlaceholders(message.fixed_pre_body),
            body: replaceEditablePlaceholders(message.bodies[0]),
            postBody: replaceEditablePlaceholders(message.fixed_post_body),
            advocate_editing_permission: message.group.advocate_editing_permission,
            id: message.group.id,
            name: message.group.name,
        }
        if (!targets[message.group.id]) targets[message.group.id] = []
        targets[message.group.id].push({
            imageUrl: message.target.image_url,
            label: message.target.name,
            value: message.target.id,
            targeted: true,
            ...(message.target.is_custom && { is_custom: true }),
        })
    }
    return { ...campaign, messages, targets }
}

export const convertRegulationMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
        },
    }
    return { ...campaign, messages }
}

export const convertWriteWhiteHouseMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
            subject: replaceEditablePlaceholders(campaign.messages[0].subject),
        },
    }
    return { ...campaign, messages }
}

const fetchVideoRecordingURL = async ({ queryKey }) => {
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_share_story_recording_url",
        params: {
            campaign_id: queryKey[1],
            decode_enums: "false",
        },
    })
    return response?.json?.()
}

export const useVideoRecordingURL = (campaign: Campaign) => {
    const videoCampaigns = [ShareStoryType.text_and_video.value, ShareStoryType.video.value] as number[]
    const isVideoCampaign = videoCampaigns.includes(campaign.share_story_type)
    return useQuery({
        queryKey: ["get_share_story_recording_url", campaign.id],
        queryFn: fetchVideoRecordingURL,
        enabled: isVideoCampaign,
        staleTime: Infinity,
    })
}

const fetchVideoShareStoryVideo = async ({ queryKey }) => {
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_share_story_video_url",
        params: {
            video_id: queryKey[1],
            gather_voices_user_id: queryKey[2],
            campaign_id: queryKey[3],
            decode_enums: "false",
        },
    })
    return response?.json?.() as Promise<{
        video_embedded_url: string
        video_url: string
        thumbnail_path: string
        status: "in_progress" | "completed"
    }>
}

export const useShareStoryVideo = (videoGVId: string | null, userGVId: string | null, campaign_id: string) => {
    return useQuery({
        queryKey: ["get_share_story_video_url", videoGVId, userGVId, campaign_id],
        queryFn: fetchVideoShareStoryVideo,
        enabled: Boolean(videoGVId),
        staleTime: Infinity,
    })
}

const fetchCampaign = async ({ queryKey }) => {
    const parentId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_multi_action_children",
        params: {
            parent_campaign_id: parentId,
            decode_enums: "false",
            dehydrate_extra: "can_participate,message_groups",
        },
    })
    return response?.json?.()
}

export const useSimpleGetCampaignMessages = (campaignId: string) =>
    useQuery({
        queryKey: ["get_campaign_messages", campaignId],
        queryFn: fetchSimpleCampaignMessages,
        enabled: true,
        staleTime: Infinity,
    })

const fetchSimpleCampaignMessages = async ({ queryKey }) => {
    const campaignId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_campaign_messages",
        params: { campaign_id: campaignId, decode_enums: "false", count: "false" },
    })
    return response.json()
}

export const getCampaignUrlsFromId = async (campaignIds: string[], missingRawUrls: string[]) => {
    return await api
        .get({
            model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
            action: "get_campaign_ac_urls_from_campaign_placeholder_ids",
            params: {
                ids: campaignIds.join(","),
                urls: missingRawUrls.join(","),
                decode_enums: "false",
            },
        })
        .then((response) => response.json())
        .catch((error) => ({ error }))
}

export const deleteVideoFromGatherVoices = async (body: {
    campaign_id: string
    gather_voices_video_id: string
    gather_voices_user_id: string
}) => {
    const options = {
        throwOnNotOk: false,
        headers: {
            "X-Csrftoken": getCSRF(),
        },
    }

    // using patch instead of delete because api does not support delete
    return await api.patch(
        {
            model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
            action: "delete_gather_voices_video",
        },
        body,
        options,
    )
}

export const postGrassrootsAction = async (body) => {
    return await api.post(
        {
            model: DjangIO.app.grassroots.models.GrassrootsSupporterAction,
            params: "decode_enums=false&count=false&exclude=%7B%22interaction_type%22%3A34%7D&archived=false",
        },
        body,
        {
            headers: {
                "X-Csrftoken": getCSRF(),
            },
        },
    )
}

export const updateSupporterHasParticipatedInCampaigns = async () => {
    const participatedCampaigns = window.userdata.participated_campaigns
    const hasEverParticipated = window.userdata.has_participated_in_any_campaign_dn

    if (participatedCampaigns?.length === 0 || !hasEverParticipated) {
        return await api.patch(
            {
                model: DjangIO.app.grassroots.models.Supporter,
                action: "patch_supporter_has_participated_in_any_campaign_dn",
            },
            { has_participated_in_any_campaign_dn: true, supporterId: window.userdata.id },
            {
                throwOnNotOk: false,
                headers: {
                    "X-Csrftoken": getCSRF(),
                },
            },
        )
    }
}
